import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/asides/CustomerLogin.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/asides/ProductAdditionalInformation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/asides/ProductDescription.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/asides/ProductReviews.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/asides/ShopFilter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/LoginFormPopup.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/ScrollTop.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/footers/MobileFooter1.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/headers/MobileHeader.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/modals/NewsLetter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/shopCartandCheckout/CartDrawer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/context/Context.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuProvider"] */ "/vercel/path0/context/MenuContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/context/UserContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/kanit/Kanit-Medium.ttf\"}],\"variableName\":\"myFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tooltip/dist/react-tooltip.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/css/plugins/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/sass/style.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/rc-slider/assets/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/tippy.js/dist/tippy.css");
